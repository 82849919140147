<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div>
          <b-row>
            <b-col
              md="6"
              class="flex justify-content-start"
            />
            <b-col
              md="6"
            >
              <div class="d-flex justify-content-end">
                <b-form-select
                  v-model="search_type"
                  class="d-inline-block mr-1"
                  :options="search_type_option"
                />

                <b-form-input
                  v-model="search_val"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  block
                  @click="gethistory()"
                >
                  <feather-icon icon="SearchIcon" />
                  Search
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(status_result)="data">
          <b-badge
            :variant="
              data.item.status === 'cancle'
                ? 'danger': data.item.status_result === 'waiting'
                  ? 'info': 'success'
            "
            class="badge-glow"
          >
            {{
              data.item.status === 'cancle'
                ? 'ยกเลิกโพย':data.item.status_result === 'waiting'
                  ? 'รอผล': 'ออกผลแล้ว'
            }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.status_result === 'waiting' && data.item.status === 'confirm'"
              variant="warning"
              @click="RefundList(data.item._id)"
            >
              <feather-icon
                :id="`invoice-row-${data.item._id}-preview-icon`"
                icon="CornerDownLeftIcon"
                size="16"
              />
              <small>ยกเลิกโพย</small>
            </b-badge>

          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="gethistory()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="gethistory()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  // BInputGroupPrepend,
  // BModal,
  BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination,
  // BInputGroup,
  BFormInput, BButton, BCardBody, VBToggle, BOverlay, BIconController, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    // vSelect,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormCheckbox,
    BBadge,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_val: null,
      search_type: 1,
      show: true,
      depositdata: [],
      perPage: 15,
      pageOptions: [10, 15, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'LottoHeadName', label: 'หวย' },
        { key: '_id', label: 'ไอดีโพย' },
        { key: 'count', label: 'จำนวนรายการ' },
        {
          key: 'totalAmount',
          label: 'จำนวนเงิน',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        { key: 'status_result', label: 'สถานะ' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'เครื่องมือ' },
      ],
      /* eslint-disable global-require */
      items: [],
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        { value: 2, text: 'ไอดีโพย' },
      ],
      addNewDataSidebar: false,
      Interval: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  created() {
    // this.Interval = setInterval(() => {
    //   this.gethistory()
    // }, 30000)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.gethistory()
    // this.Get_bank()
  },
  methods: {
    RefundList(ListID) {
      this.show = true
      const formData = {
        ListID,
      }
      this.$http
        .post('https://staffapi.ma5lotto.com/api/admin/cencellist', formData)
        .then(() => {
          this.show = false
          this.gethistory()
          this.Success('ยกเลิกโพยเรียบร้อยค่ะ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Export() {
      window.open(
        'https://papi.ava168.net/api/export',
        // 'https://papi.ava168.net/api/export',
      )
    },
    Get_bank() {
      this.$http
        .get('https://staffapi.ma5lotto.com/api/bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    async gethistory() {
      // const index = 0
      this.show = true
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
      }
      this.$http
        .get(
          'https://staffapi.ma5lotto.com/api/history/list',
          { params },
        )
        .then(response => {
          this.show = false
          this.items = response.data.Data
          this.totalRows = response.data.total
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    getBalance(username, index) {
      const params = {
        username,
      }
      this.$http
        .get('https://papi.ava168.net/api/balance/getbalance', { params })
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.depositdata[index].balance = response.data.balance
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
